import React, {Fragment} from 'react'
import { Link } from 'react-router-dom'
import axios from "axios";
import environment from "../../Environments/environments";
import { guardAdmin } from '../Admin/admin-base'
import { getBusyIndicator } from '../../Helpers/busyIndicator'
import { AgGridReact } from 'ag-grid-react';
import { DefaultConfig } from '../../Helpers/defaultConfigHelper';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './metadataManagement.css'

class BtnCellRenderer extends React.Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        this.props.clicked(this.props.value);
    }

    render() {
        return (
            <div className="meta-item">
                <a onClick={this.btnClickedHandler}>Delete</a>
            </div>
        )
    }
}

export class MetadataManagement extends React.Component {
    defaultStyle = {
        'text-align': 'left'
    }

    columnDefs = [
        {field: 'account', headerName: 'Account', sortable: true, cellStyle: this.defaultStyle},
        {field: 'uploadedBy', headerName: 'Uploaded By', sortable: true, cellStyle: this.defaultStyle},
        {field: 'fileType', headerName: 'File Type', sortable: true, cellStyle: this.defaultStyle, width: 95},
        {field: 'uploadDateTime', headerName: 'Upload Date/Time', sortable: true, cellStyle: this.defaultStyle, valueFormatter: this.onDateRender},
        {
            field: 'filename',
            headerName: '', 
            cellRenderer: 'btnCellRenderer',
            cellRendererParams: {
                clicked: (field) => this.onDelete(field)
            },
            width: 100
        }
    ]

    constructor(props) {
        super(props)

        this.user = JSON.parse(sessionStorage.getItem('loggedInUser'))
        this.config = DefaultConfig.getDefaultConfig()

        this.state = {
            files: [],
            loading: false,
            columnDefs: this.columnDefs,
            frameworkComponents: {
                btnCellRenderer: BtnCellRenderer,
            }
        }
    }

    componentDidMount() {
        let footer = document.getElementById('Footer');
        footer.style.position = 'relative';

        this.refreshData()
    }

    refreshData() {
        this.setState({loading: true}, () => {
            this.loadDataFromServer()
        })
    }

    loadDataFromServer() {
        axios
            .get(`${environment.baseUrl}/api/metadata/enumerate`, this.config)
            .then(response => {

                if (response.data.error) {
                    alert('Error getting listing: ' + response.data.error)
                    return;
                }

                this.setState({
                    files: response.data.files,
                    loading: false
                })
            })
            .catch(error => {
                console.error('Error loading metadata listing: ', error, error.response);
                alert('Error Loading Listing');

                this.setState({
                    loading: false
                })
            })
    }

    onDateRender(params) {
        let value = params.value
        let month = parseInt(value.substring(4,6), 10)
        let day = parseInt(value.substring(6,8), 10)
        let hour = parseInt(value.substring(9,11), 10)
        let meridian = 'AM'
    
        if (hour > 12) {
            hour -= 12
            meridian = 'PM'
        }
    
        return month + '/' + day + '/' + value.substring(0,4)
            + ' ' + hour + ':' + value.substring(11,13) + ':' + value.substring(13,15) 
            + ' ' + meridian
    }

    uploadMeta() {
        this.props.history.push('/admin/uploadMeta')
    }

    onDelete = async (filename) => {
        if (!window.confirm("Are you sure you want to delete this file?")) {
            return false
        }

        let data = {
            filename: filename,
            sblid: this.user.sblId
        }

        return await axios
            .post(`${environment.baseUrl}/api/metadata/delete`, data, this.config)
            .then(response => {
                if (response.data.error) {
                    alert('Error deleting record: ' + response.data.error)
                    return false;
                }

                alert('Record deleted successfully.')

                this.refreshData()
            })
            .catch(error => {
                console.error('Error calling delete record: ', error.response)
                alert('Error deleting record');
            })
    }

    onGridReady = (params) => {
        let defaultSortModel = [
            { colId: 'account', sort: 'asc'},
            { colId: 'uploadDateTime', sort: 'asc'}
        ]

        params.api.setSortModel(defaultSortModel)
        params.api.sizeColumnsToFit()
    }

    metaFiles() {
        return (
            <div 
                id="meta-grid-container"
                className="ag-theme-alpine"
                style = {{ height: '305px', width: '1000px' }}>
                    <AgGridReact
                        suppressRowClickSelection={true}
                        frameworkComponents={this.state.frameworkComponents}
                        enableCellTextSelection={false}
                        onGridReady={this.onGridReady}
                        columnDefs={this.state.columnDefs}
                        rowData={this.state.files}>
                    </AgGridReact>
            </div>
        )
    }

    render() {
        return guardAdmin(
            <Fragment>
                {getBusyIndicator(this.state.loading, 
                    <div className="meta-manager">
                        <div className="TextContainer container">
                            <div className="title-section col-xs-11 text-center">
                                <div className="col-xs-12">
                                    <div className="meta-text-container">
                                        <span className="meta-text">Metadata Management</span>
                                    </div>

                                    <div className="MetaAddButton">
                                        <Link to={`/admin/uploadMeta`}>Upload Metadata</Link>
                                    </div>

                                    {this.metaFiles()}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        )
    }
}
