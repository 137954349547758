import React from 'react'
import { Link } from 'react-router-dom'
import Moment from 'moment';

import './adminFooter.css'

export class AdminFooter extends React.Component {
    
    componentWillUpdate() {
        let footer = document.getElementById('Footer');
        footer.style.position = 'absolute';
    }

    gotoAdd = (e) => {
        window.location = '/admin/bookEditor/new'
    }

    render() {
        return (
            <div className="AdminFooter" id="Footer" style={{position: "absolute"}}>
                <div className="LeftFooterContainer">
                    <div className="CopyrightContainer">
                        <span>©{Moment().year()} Society of Biblical Literature</span>
                    </div>
                </div>
                <div className="RightFooterContainer">
                    <div className="FooterTextContainer">
                        <div className="ReportsLinkContainer">
                            <Link to={`/admin/reports`}>Reports</Link>
                        </div>
                        <div className="SystemManagementLinkContainer">
                            <Link to={`/admin/system`}>System Management</Link>
                        </div>
                        <div className="AddBookLinkContainer">
                            <Link to={'#'} onClick={this.gotoAdd}>Add Book</Link>
                        </div>
                        <div className="JournalsContainer">
                            <Link to={'/admin/journalsmenu'}>Journals</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
