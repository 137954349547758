import * as React from 'react';
import { AdminFilterBar } from '../componentsBarrel';
import { AdminSearchComponent } from '../componentsBarrel';
import ReactPaginate from 'react-paginate';

import './adminSearchResultsPageComponent.css';

export const AdminSearchResultsPageComponent = (props) => (
  <div className="AdminSearchResultsPageComponent">
    <div className="BackgroundImageSection">
      <div className="SearchAndTitleContainer">
        <AdminSearchComponent 
          searchTerm={props.searchTerm}
          keywordType={props.keywordType}
        />
      </div>
    </div>
    <div className="NumberOfResultsSection">
      <div className="row">
        <div className="col-xs-2" />
        <div className="col-xs-7 MiddleSection">
          <div className="NumberOfResultsContainer">
            <div className="MiddleSectionContent">
              {props.displayShowingText(props.pageSelectedIndex, props.perPage, props.numTotalResults, props.searchTerm, props.filterData.data.length)}
            </div>
          </div>
        </div>
        <div className="col-xs-3 RightSection">
          <div className="SortByContainer">
            <label id="SortBySelectLabel" htmlFor="sel1" className="NoWrap">Sort By </label>
            <select className="form-control SortBySelectDropdown" id="sel1" onChange={props.sortResults} value={props.sortTerm}>
              {(props.browseAll ? null : <option value="Relevance desc,TitleSortable">Relevance</option>)}
              <option value="TitleSortable">Title: Ascending</option>
              <option value="TitleSortable desc">Title: Descending</option>
              <option value="LastName,FirstName">Author/Editor: Ascending</option>
              <option value="LastName desc,FirstName desc">Author/Editor: Descending</option>
              <option value="Year">Year: Ascending</option>
              <option value="Year desc">Year: Descending</option>
              <option value="Created">Date Entered: Ascending</option>
              <option value="Created desc">Date Entered: Descending</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div className="SearchResultsSection container-fluid">
      <div className="row">
        <div className="col-xs-2 FilterBarSection">
          <AdminFilterBar filterData={props.filterData} onFilterClick={props.onFilterClick} 
            handleContentTypeClick={props.handleContentTypeClick} handleMeetingTypeClick={props.handleMeetingTypeClick}
            isSeeMoreYearsHidden={props.isSeeMoreYearsHidden} />
        </div>
        <div className="col-xs-8 MiddleSection">
          <div className="SearchResultsContainer">
            <div className="MiddleSectionContent">
              <div>{props.renderLoaderOrSearchResults()}</div>
              <div className="PaginationSection">
                <ReactPaginate
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  breakLabel={<span>...</span>}
                  breakClassName={'break-me'}
                  pageCount={props.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={props.handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                  forcePage={props.pageSelectedIndex}
                />
                <div className="form-group PerPageSelect">
                  <label htmlFor="sel1">Results Per Page:</label>
                  <select className="form-control standard-select" id="sel1" onChange={props.updateResultsPerPage} value={props.perPage}>
                    <option>20</option>
                    <option>50</option>
                    <option>75</option>
                    <option>100</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-2 RightSection" />
      </div>
    </div>
  </div>
);