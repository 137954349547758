import React, {Fragment} from 'react'
import axios from 'axios'
import DataCallerBaseComponent from '../Core/dataCallerBaseComponent'
import environment from "../../Environments/environments"
import ReactExport from "react-data-export"
import ReactTooltip from 'react-tooltip'

import { guardAdmin } from '../Admin/admin-base'
import NumberFormat from 'react-number-format'
import { getBusyIndicator } from '../../Helpers/busyIndicator'
import Moment from 'moment'

export class NotificationsSnapshotReport extends DataCallerBaseComponent {
    constructor(props) {
        super(props)

        this.state = {
            loading: false
        }

        this.reportTitle = 'Notifications Snapshot'
        this.todaysDate = new Date()
    }

    componentDidMount() {
        this.setState({loading: true}, () => this.loadDataFromServer())

        let footer = document.getElementById('Footer');
        footer.style.position = 'relative';
    }

    loadDataFromServer() {
        this.makeAPICall('/api/notificationsSnapshot/currentSBLMemberCount', 'currentSBLMemberCount');
        this.makeAPICall('/api/notificationsSnapshot/currentBookMemberCount', 'currentBookMemberCount');
        this.makeAPICall('/api/notificationsSnapshot/currentJournalMemberCount', 'currentJournalMemberCount');
        this.makeAPICall('/api/notificationsSnapshot/bookSubjectsAverage', 'averageBookSubjectsSelected');
        this.makeAPICall('/api/notificationsSnapshot/bookSubjectsMedian', 'medianBookSubjectsSelected');
        this.makeAPICall('/api/notificationsSnapshot/bookSubjectsMode', 'modeBookSubjectsSelected');
        this.makeAPICall('/api/notificationsSnapshot/journalsAverage', 'averageJournalsSelected');
        this.makeAPICall('/api/notificationsSnapshot/journalsMedian', 'medianJournalsSelected');
        this.makeAPICall('/api/notificationsSnapshot/journalsMode', 'modeJournalsSelected');
    }

    formatDate(date) {
        return Moment(date).format("MM/DD/YYYY hh:mm A");
    }

    makeAPICall(uri, stateParameter) {
        let url = environment.baseUrl.concat(uri)

        return axios.get(url, this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    console.error('Error: ', response.data.message)
                    alert('Error getting Notifications data for the api: ' + uri + '. Error message: ' + response.data.message)
                    return;
                }

                switch (stateParameter){
                    case "currentSBLMemberCount":
                        this.setState({ currentSBLMemberCount: response.data.data })
                        break
                    case "currentBookMemberCount":
                        this.setState({ currentBookMemberCount: response.data.data })
                        break
                    case "currentJournalMemberCount":
                        this.setState({ currentJournalMemberCount: response.data.data })
                        break
                    case "averageBookSubjectsSelected":
                        this.setState({ averageBookSubjectsSelected: response.data.data })
                        break
                    case "medianBookSubjectsSelected":
                        this.setState({ medianBookSubjectsSelected: response.data.data })
                        break
                    case "modeBookSubjectsSelected":
                        this.setState({ modeBookSubjectsSelected: response.data.data })
                        break
                    case "averageJournalsSelected":
                        this.setState({ averageJournalsSelected: response.data.data })
                        break
                    case "medianJournalsSelected":
                        this.setState({ medianJournalsSelected: response.data.data })
                        break
                    case "modeJournalsSelected":
                        this.setState({ modeJournalsSelected: response.data.data })
                        break
                }
                
                this.setState({ 
                    loading: false
                }, () => true)
            })
            .catch(error => {
                console.log('Error: ', error)
                alert('An error occurred getting report data: ' + error)

                this.setState({
                    stateParameter: 0,
                    loading: false
                })
                return false;
            })
    }

    render() {
        return guardAdmin(
            <Fragment>
                {getBusyIndicator(this.state.loading, 
                    <div className="rbl-export">
                        <ReactTooltip multiline={true}/>
                        <div className="TextContainer container">
                            <div className="title-section col-xs-12 text-center">
                                <div className="col-xs-12">
                                    <div className="rbl-export-container">
                                        <span className="title-text">{this.reportTitle}</span>
                                    </div>
                                
                                    <table>
                                        <tr>
                                            <td>
                                                Date/Time
                                            </td>
                                            <td>
                                                { this.formatDate(this.todaysDate) }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Number of Current (Individual) SBL Members
                                            </td>
                                            <td>
                                                { this.state.currentSBLMemberCount }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>Book/Review Notifications</b>
                                            </td>
                                            <td />
                                        </tr>
                                        <tr>
                                            <td>
                                                Number of Current Members Signed Up
                                            </td>
                                            <td>
                                                { this.state.currentBookMemberCount }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Percentage of Current Members Signed Up
                                            </td>
                                            <td>
                                            <NumberFormat displayType={'text'} suffix={'%'} decimalScale={2}
                                                value={ this.state.currentBookMemberCount * 100/ this.state.currentSBLMemberCount } />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Number of Subjects Selected - Mean
                                            </td>
                                            <td>
                                                <NumberFormat displayType={'text'} decimalScale={0} 
                                                    value={ this.state.averageBookSubjectsSelected } />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Number of Subjects Selected - Median
                                            </td>
                                            <td>
                                                <NumberFormat displayType={'text'} decimalScale={0} 
                                                    value={ this.state.medianBookSubjectsSelected } />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Number of Subjects Selected - Mode
                                            </td>
                                            <td>
                                                <NumberFormat displayType={'text'} decimalScale={0} 
                                                    value={ this.state.modeBookSubjectsSelected } />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>Journal Notifications</b>
                                            </td>
                                            <td />
                                        </tr>
                                        <tr>
                                            <td>
                                                Number of Current Members Signed Up
                                            </td>
                                            <td>
                                                { this.state.currentJournalMemberCount }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Percentage of Current Members Signed Up
                                            </td>
                                            <td>
                                                <NumberFormat displayType={'text'} suffix={'%'} decimalScale={2}
                                                    value={this.state.currentJournalMemberCount * 100/ this.state.currentSBLMemberCount } />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Number of Journals Selected - Mean
                                            </td>
                                            <td>
                                                <NumberFormat displayType={'text'} decimalScale={0} 
                                                    value={ this.state.averageJournalsSelected } />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            Number of Journals Selected - Median
                                            </td>
                                            <td>
                                                <NumberFormat displayType={'text'} decimalScale={0} 
                                                    value={ this.state.medianJournalsSelected } />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Number of Journals Selected - Mode
                                            </td>
                                            <td>
                                                <NumberFormat displayType={'text'} decimalScale={0} 
                                                    value={ this.state.modeJournalsSelected } />
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        )
    }
}

const options = { 
    timeZone: "America/New_York", 
    hour12: true,
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    //hour: 'numeric',
    //second: 'numeric',
    //minute: 'numeric'
};
