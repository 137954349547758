import React from 'react'
import './journalsMenu.css'

export class JournalsMenu extends React.Component {
    render() {
        return (
            <div className="adminMenuPage">
                <div className="title">
                    Journal Management
                </div>

                <div className="body">
                    <div className="systemContentMenu">
                        <ul>
                            <li>
                                <a onClick={() => window.location='/admin/journals'}>
                                    Add/Edit Journals
                                </a>
                            </li>
                            <li>
                                <a onClick={() => window.location='/admin/journalIssues'}>
                                    Add/Edit Journal Issues
                                </a>
                            </li>
                            <li>
                                <a onClick={() => window.location='/admin/journalNotificationTest'}>
                                    Process Journal Notifications
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
