import React from 'react'
import { guardAdmin } from '../Admin/admin-base'

import './reports.css'

export class Reports extends React.Component {
    render() {
        return guardAdmin(
            <div className="adminMenuPage">
                <div className="title">
                    Reports
                </div>
                <div className="body">
                    <div className="systemContentMenu">
                        <ul>
                            <li>
                                <a onClick={() => window.location='/admin/accountingExport'}>
                                    Accounting Export
                                </a>
                            </li>

                            <li>
                                <a onClick={() => window.location='/admin/report/importSummaryReport'}>
                                    Import Summary
                                </a>
                            </li>

                            <li>
                                <a onClick={() => window.location='/admin/journalSelectionsSnapshot'}>
                                    Journal Selections Snapshot
                                </a>
                            </li>

                            <li>
                                <a onClick={() => window.location='/admin/notificationsSnapshot'}>
                                    Notifications Snapshot
                                </a>
                            </li>

                            <li>
                                <a onClick={() => window.location='/admin/rblexport'}>
                                    RBL Export
                                </a>
                            </li>

                            <li>
                                <a onClick={() => window.location='/admin/subjectSelectionsSnapshot'}>
                                    Subject Selections Snapshot
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
