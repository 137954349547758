import * as React from "react";
import { Fragment } from "react";
import "./bookDetailsStyles.css";
import axios from "axios";
import environment from "../../Environments/environments";
import { Link } from "react-router-dom";
import Moment from "moment";
import bookImage from "../../Assets/Images/book_with_image.png";
import { Login } from "../../Containers/Login/login";
import { ModalConsumer } from "../../Containers/Login/loginContext";
import ModalRoot from "../../Containers/Login/loginRoot";
import queryString from 'query-string';
import renderHTML from 'react-render-html';

class BookDetails extends React.Component {
  constructor(props) {
    super(props);

    var loggedInUser = {
      errorText: "",
      isAuthenticated: false,
      isAdminUser: false,
      firstName: "",
      lastName: "",
      sblId: ""
    };

    if (sessionStorage.getItem("loggedInUser")) {
      loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser"));
    }

    let search = (this.props.location.search !== '') ? queryString.parse(this.props.location.search) : {search: ''};

    this.state = {
      id: this.props.match.params.Id,
      new: search.new ? search.new : false,
      bookData: [],
      reviews: [],
      loading: true,
      loggedInUser: loggedInUser,
      hasMemberVolunteered: false,
      searchTerm: search.search,
      searchType: search.type
    };

    this.loadResultsFromServer = this.loadResultsFromServer.bind(this);
    this.returnReviewPdfLink = this.returnReviewPdfLink.bind(this);
  }

  async loadResultsFromServer() {
    if ( this.state.loading === false ) {
      this.setState({
        loading: true
      });
    }
    
    let sblId = '0';
    if ( this.state.loggedInUser && this.state.loggedInUser.isAuthenticated ) {
      sblId = this.state.loggedInUser.sblId;
    }

    let url = environment.baseUrl + '/api/book/getBookDetail/' + this.state.id + '/' + sblId;

    await axios
      .get(url, {
        params: {}
      })
      .then(response => {
        this.setState(
          {
            bookData: response.data.book,
            reviews: response.data.book.reviews,
            hasMemberVolunteered: response.data.hasMemberVolunteered,
            loading: false
          });
      })
      .catch(function(error) {
        console.error('Error getting book data: ', error, error.response);
      });
  }

  componentDidMount() {
    this.loadResultsFromServer();

    let footer = document.getElementById('Footer');
    footer.style.position = 'relative';
  }

  returnBookTitle() {
    var title = this.state.bookData.title;
    if (this.state.bookData.subtitle) {
      title = title + ": " + this.state.bookData.subtitle;
    }
    return title;
  }

  returnFormats() {
    if (this.state.bookData.formats) {
      return (
        <div>
          <span>
            <strong>Formats: </strong>
          </span>
          {this.state.bookData.formats}
        </div>
      );
    }
  }

  returnSubjects() {
    if (this.state.bookData.subjects) {
      var subjects = String(this.state.bookData.subjects).split("|");

      var searchType = this.state.searchType ? this.state.searchType : 0

      return (
        <div>
          <span>
            <strong>Subjects: </strong>
          </span>
          {subjects.map(function(subject, index) {
            if (subject) {
              return (
                <Link
                  key={index}
                  to={
                    this.props.basePath + `/searchResults/` +
                    subject.trim().replace(/\//g, "%2F") +
                    "?kw=subsearch&reset=true" +
                    "&type=" + searchType
                  }
                >
                  {subject.trim()}
                  {index < subjects.length - 1 ? ", " : ""}
                </Link>
              );
            }
            return '';
          }, this)}
        </div>
      );
    }
  }

  returnKeywords() {
    if (this.state.bookData.keywords === null || this.state.bookData.keywords === '') {
      return ''
    }

    return (
      <div>
        <span><strong>Keywords: </strong></span>
        {this.state.bookData.keywords}
      </div>
    )
  }

  returnSeriesInfo() {
    if (this.state.bookData.seriesInfo) {
      return (
        <div>
          <span>
            <strong>Series: </strong>
          </span>
          {this.state.bookData.seriesInfo}
        </div>
      );
    }
  }

  returnPublisherURL() {
    if (this.state.bookData.publisherURL) {
      return (
        <div>
          <a href={this.state.bookData.publisherURL} target="_blank">
            DOI/Publisher Website
          </a>
        </div>
      );
    }
  }

  returnBookEdition() {
    if (this.state.bookData.edition) {
      return (
        <div>
          <span>
            <strong>Edition: </strong>
          </span>
          {this.state.bookData.edition}
        </div>
      );
    }
  }

  returnBookAuthors() {
    if (this.state.bookData.author) {
      return (
        <div>
          <span>
            <strong>Author: </strong>
          </span>
          {this.state.bookData.author}
        </div>
      );
    }
  }

  returnBookEditors() {
    if (this.state.bookData.editor) {
      return (
        <div>
          <span>
            <strong>Editor: </strong>
          </span>
          {this.state.bookData.editor}
        </div>
      );
    }
  }

  returnBookTranslators() {
    if (this.state.bookData.translator) {
      return (
        <div>
          <span>
            <strong>Translator: </strong>
          </span>
          {this.state.bookData.translator}
        </div>
      )
    }
  }

  returnDescription() {

    let description = this.state.bookData.description

    if (this.state.bookData.description) {
      return (
        <div>
          <span>
            <strong>Description: </strong>
          </span>

          {renderHTML('<div>' + description + '</div>')}
        </div>
      );
    }
  }

  returnVolunteerLink(url, loggedInUser) {
    if (loggedInUser && loggedInUser.isAuthenticated) {
      if ( this.state.hasMemberVolunteered ) {
        return (
          <span><b>You have previously volunteered to review this book. Thank you!</b></span>
        )
      }
      else {
        let title = this.returnBookTitle()
        sessionStorage.setItem('book_' + this.state.id, title)

        return (
          <span>
            <Link to={`/home/volunteer/` + this.state.id}>Volunteer to Review This Title for RBL</Link>
          </span>
        );
      }
    } else {
      return (
        <span>
          <ModalRoot />
          <ModalConsumer>
            {({ showModal }) => (
              <Fragment>
                <Link
                  to={'#'}
                  onClick={(e) => {
                    e.preventDefault();
                    showModal(Login)
                  }}
                >
                  Sign In to Volunteer to Review This Title for RBL
                </Link>
              </Fragment>
            )}
          </ModalConsumer>
        </span>
      );
    }
  }

  returnReviews() {
    if (!this.state.loading) {
      if (this.state.reviews.length > 0) {
        var reviews = this.state.reviews;
        return (
          <div>
            <hr />
            <span>
              <strong>Reviews: </strong>
            </span>
            {reviews.map(function(review, index) {
              var reviewDate;
              if (review.reviewedOn) {
                reviewDate = (
                  <span>
                    Published {Moment(review.reviewedOn).format("YYYY-MM-DD")}{" "}
                    <br />{" "}
                  </span>
                );
              } else {
                reviewDate = "";
              }
              return (
                <div key={review.reviewedOn + review.names}>
                  <span>
                    <strong>Review by {review.names}</strong>
                  </span>{" "}
                  <br />
                  {reviewDate}
                  {this.returnReviewPdfLink(
                    review.reviewURL,
                    this.state.loggedInUser
                  )}

                  {this.getReviewDivider(index, reviews.length-1)}
                </div>
              );
            }, this)}
          </div>
        );
      }
    }
  }

  getReviewDivider(index, count) {
    return (index < count) ? <hr/> : ''
  }

  returnReviewPdfLink(url, loggedInUser) {
    if (loggedInUser && loggedInUser.isAuthenticated) {
      return (
        <span>
          <a href={url} target="_blank" rel="noopener noreferrer">
            Read the Review (PDF)
          </a>
        </span>
      );
    } else {
      return (
        <span>
          <ModalRoot />
          <ModalConsumer>
            {({ showModal }) => (
              <Fragment>
                <Link
                  to={"#"}
                  onClick={(e) => {
                    e.preventDefault();
                    showModal(Login)
                  }}
                >
                  Sign In to Read the Review
                </Link>
              </Fragment>
            )}
          </ModalConsumer>
        </span>
      );
    }
  }

  returnBackToSearchResults() {
    if ( this.state.new 
      || !this.state.searchType 
      || this.props.history.length <= 1 ) {
        return ''
    }

    return (
      <Link to={'#'} onClick={(e) => {
          e.preventDefault();
          this.props.history.goBack();
      }}>
        Back to Search Results
      </Link>
    );
  }

  returnPageCount() {
    if (!this.state.bookData.numberOfPages || this.state.bookData.numberOfPages === '') {
      return ''
    }

    return (
      <div>
        <span><strong>Page Count: </strong></span>
        {this.state.bookData.numberOfPages}
      </div>
    )
  }

  render() {
    return (
      <Fragment>
        {this.state.loading ? (
          <div className="sk-circle">
            <div className="sk-circle1 sk-child" />
            <div className="sk-circle2 sk-child" />
            <div className="sk-circle3 sk-child" />
            <div className="sk-circle4 sk-child" />
            <div className="sk-circle5 sk-child" />
            <div className="sk-circle6 sk-child" />
            <div className="sk-circle7 sk-child" />
            <div className="sk-circle8 sk-child" />
            <div className="sk-circle9 sk-child" />
            <div className="sk-circle10 sk-child" />
            <div className="sk-circle11 sk-child" />
            <div className="sk-circle12 sk-child" />
          </div>
        ) : (
          <div className="BookDetailsPage">
            <div className={this.props.calculateBackgroundImageClass()}>
              <div className="SearchAndTitleContainer">
                {this.props.getSearchContainer(this)}
              </div>
            </div>
            <div className="container ContentSection">
              <div className="ContentContainer">
                <div className="BackButtonContainer">
                  {this.returnBackToSearchResults()}
                </div>
                <div className="row ImageAndDetailsRow">
                  <div className="col-xs-3 ImageSection">
                    <div className="ConferencePaperImageContainer">
                      <img
                        id="BookImage"
                        src={this.state.bookData.imageURL || bookImage}
                        alt="Book"
                      />
                    </div>
                  </div>
                  <div className="col-xs-9 DetailsSection">
                    <div className="DetailsContainer">
                      {this.props.returnBookEditLink(this)}
                      <div>
                        <span>
                          <strong>Book Title: </strong>
                        </span>
                        {this.returnBookTitle()}
                      </div>
                      {this.returnBookEdition()}
                      {this.returnBookAuthors()}
                      {this.returnBookEditors()}
                      {this.returnBookTranslators()}
                      {this.returnSeriesInfo()}
                      <div>
                        <span>
                          <strong>Publisher: </strong>
                        </span>
                        {this.state.bookData.publisherName}
                      </div>
                      <div>
                        <span>
                          <strong>Copyright: </strong>
                        </span>
                        {this.state.bookData.copyright}
                      </div>
                      {this.props.getBookStatusDisplay(this)}
                      {this.returnPageCount()}
                      {this.returnFormats()}
                      {this.returnSubjects()}
                      {this.returnKeywords()}
                      {this.returnDescription()}
                      {this.returnPublisherURL()}
                      <div>
                        <a href={this.state.bookData.amazonURL} target="_blank">
                          Amazon.com
                        </a>
                      </div>
                      {this.props.returnVolunteerEditLink(this)}
                      {this.returnReviews()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export { BookDetails };
