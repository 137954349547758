import React, { Fragment } from 'react'
import axios from "axios";
import environment from "../../Environments/environments";
import { Link } from 'react-router-dom'
import ModalRoot, {ModalRootStacked} from "../../Containers/Login/loginRoot";
import { guardAdmin } from '../../Components/Admin/admin-base'
import { Checkbox } from 'react-bootstrap'
import { getBusyIndicator } from '../../Helpers/busyIndicator'
import { MessageFlash } from '../../Helpers/messageFlashHelper'
import { DefaultConfig } from '../../Helpers/defaultConfigHelper';

import { 
    CollectedWorksEssays, 
    TextField, 
    InputField, 
    Contributors,
    ExpertiseComponent,
    BookFormats,
    BookStatus,
    BookReviews,
    AccountSelect
} from '../componentsBarrel'

import bookImage from "../../Assets/Images/book_with_image.png";

import './bookEditor.css'
import { BookStatusFilterEnum, BookStatusEnum } from '../../Helpers/bookStatusHelper';

export class BookEditor extends React.Component {
    constructor(props) {
        super(props);

        this.user = JSON.parse(sessionStorage.getItem('loggedInUser'))
        if (!this.user || this.user === null) {
            window.location = '/'
            return
        }

        this.config = DefaultConfig.getDefaultConfig()

        const bookStatusName = this.user.firstName + ' ' + this.user.lastName

        this.state = {
            titleId: this.props.match.params.Id,
            id: 0,
            loading: true,
            title: '',
            subtitle: '',
            description: '',
            publisherId: 0,
            publisherName: '',
            publisherLocation: '',
            contributors: [],
            formats: [],
            numberOfPages: '',
            copyright: '',
            seriesTitle: '',
            seriesNumber: '',
            edition: '',
            imageUrl: '',
            keywords: '',
            doiPermanentURL: '',
            preview: '',
            selectedFile: null,
            subjects: [],
            essays: [],
            reviews: [],
            bookStatusId: 1,
            bookStatusNote: '',
            bookStatusName: bookStatusName,
            statusEntry: '',
            suppressEmailNotifications: false,
            isClassicRBLImport: false,
            accounts: [],
            accountId: -1,
            isAcceptingVolunteers: false,
            sourceFile: ''
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        let footer = document.getElementById('Footer');
        footer.style.position = 'relative';

        this.setState({loading: true}, async () => {
            await this.loadAccounts()
            this.loadBook()
        })
    }

    loadBook() {
        if (this.isNewBook()) {
            this.setState({ loading: false })
            return;
        }

        const url = environment.baseUrl + '/api/book/bookEdit/' + this.state.titleId

        axios
            .get(url, this.config)
            .then((response) => {
                this.updateBookData(response.data);
            })
            .catch((error) => {
                console.error('Error loading book data: ', error);
                alert('Error Loading Book: ' + error);
                this.setState({
                    loading: false
                })
            })
    }

    updateBookData(data) {
        var book = this.scrubData(data);

        //console.debug('book: ', book)

        this.setState({
            id: book.id,
            title: book.title,
            subtitle: book.subtitle,
            description: book.description,
            publisherName: book.publisherName,
            publisherLocation: book.publisherLocation,
            publisherId: book.publisherId,
            contributors: book.contributors,
            copyright: book.copyright,
            formats: book.formats,
            edition: book.edition,
            seriesTitle: book.seriesTitle,
            seriesNumber: book.seriesNumber,
            imageUrl: book.imageURL,
            keywords: book.keywords,
            doiPermanentURL: book.doiPermanentURL,
            numberOfPages: book.numberOfPages,
            subjects: book.subjects,
            essays: book.essays,
            reviews: book.reviews,
            bookStatusId: book.bookStatusId,
            suppressEmailNotifications: book.suppressEmailNotifications,
            isClassicRBLImport: book.isClassicRBLImport,
            accountId: book.accountId,
            loading: false,
            isAcceptingVolunteers: book.isAcceptingVolunteers,
            sourceFile: book.sourceFile
        })
    }

    loadAccounts() {
        return axios
            .get(environment.baseUrl + '/api/accounts/enumerate', this.config)
            .then(response => {
                if (response.data.error) {
                    alert(response.data.error)
                    return;
                }

                this.setState({
                    accounts: response.data.accounts
                })
            })
            .catch(error => {
                console.error('Error loading data: ', error, error.response);
                alert('Error Loading Data');

                this.setState({
                    accounts: []
                })
            })
    }

    scrubData(data) {
        data.description = data.description ? data.description : ''
        data.numberOfPages = data.numberOfPages ? data.numberOfPages : ''
        data.seriesTitle = data.seriesTitle ? data.seriesTitle : ''
        data.serisNumber = data.seriesNumber ? data.seriesNumber : ''
        data.copyright = data.copyright ? data.copyright : ''
        data.edition = data.edition ? data.edition : ''
        data.keywords = data.keywords ? data.keywords : ''
        data.doiPermanentURL = data.doiPermanentURL ? data.doiPermanentURL : ''
        data.sourceFile = data.sourceFile ? data.sourceFile : 'Not Applicable'

        data.publisherName = data.publisherName ? data.publisherName : ''
        data.publisherLocation = data.publisherLocation ? data.publisherLocation : ''

        data.contributors = data.contributors ? data.contributors : []
        data.formats = data.formats ? data.formats : []
        data.subjects = data.subjects ? data.subjects : []
        data.essays = data.essays ? data.essays : []
        data.reviews = data.reviews ? data.reviews : []

        return data;
    }

    isNewBook() {
        return this.state.titleId === null || this.state.titleId === 0 || this.state.titleId === 'new';
    }

    showPreview = (e) => {
        const self = this
        const file = e.target.files[0]
        const _URL = window.URL || window.webkitURL;

        let filename = e.target.value

        if (!this.checkExtension(filename)) {
            alert("Invalid file type. Image must be GIF, JPEG, or PNG format.")
            this.fileUpload.value = ''
            return false
        }

        const sizeInMb = file.size / 1024 / 1024
        if (sizeInMb > 5) {
            this.fileUpload.value = ''
            let sizeErrorMessage = "Image file must be equal to or less than 5 MB."
            alert(sizeErrorMessage + "  The selected file is " + Math.ceil(sizeInMb) + "mb.")
            return false
        }

        var reader = new FileReader()

        reader.onload = function (e) {
            let image = new Image();

            image.onload = function () {

                self.setState({
                    preview: e.target.result,
                    selectedFile: file
                })
            };

            image.src = _URL.createObjectURL(file);
        }

        reader.readAsDataURL(e.target.files[0])
    }

    checkExtension(filename) {
        var hash = {
            '.jpg': 1,
            '.jpeg': 1,
            '.png': 1,
            '.gif': 1
        };

        const re = /\.[0-9a-z]+$/i
        const ext = filename.toLowerCase().match(re);

        return hash[ext]
    }

    onBrowseClick = (e) => {
        e.preventDefault();
        this.fileUpload.click();
    }

    getImageDisplay() {
        let source = this.state.preview === '' ? this.state.imageUrl || bookImage : this.state.preview;

        return (
            <div id="imageDisplay">
                <img
                    id="BookImage"
                    src={source}
                    alt="Book"
                />
                <input type="file"
                    id="avatar" name="avatar"
                    accept="image/png, image/jpeg, image/gif, image/jpg"
                    onChange={this.showPreview}
                    ref={(ref) => this.fileUpload = ref}
                />
                <button id="file-button" onClick={this.onBrowseClick}>Browse</button>
            </div>
        )
    }

    backButtonClick = (e) => {
        e.preventDefault();
        this.props.history.goBack();
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    exactlyFourDigits(value) {
        let numberFormat = /^\d{4}$/
        return numberFormat.test(value)
    }

    validateCopyright() {
        if (this.state.copyright
            && this.state.copyright.length === 4
            && this.exactlyFourDigits(this.state.copyright)) {
            return true
        }

        return false;
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        if (this.validateCopyright() === false) {
            alert('Copyright must be entered as a four-digit year.')
            return false
        }

        if (this.state.contributors.length === 0) {
            alert('Book must have at least one contributor')
            return false
        }

        if (this.state.formats.length === 0) {
            alert('Book must have at least one format')
            return false
        }

        if (this.state.isClassicRBLImport !== true) {
            if (this.state.accountId < 1) {
                alert('Must select a Bill To account.')
                return false
            }
        }

        if (this.state.description.length > 5000) {
            alert('The Description field contains more than the 5,000 character maximum.')
            return false
        }

        const isNew = this.isNewBook();

        let results = await this.uploadReviews()
        if (!results.every(x => x === true)) {
            return false;
        }

        if (this.state.preview) {
            const titleId = await this.saveData(isNew);

            const formData = new FormData();
            formData.append('files', this.state.selectedFile)
            formData.append('titleId', titleId)

            const url = environment.baseUrl + '/api/image/upload'
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + this.user.token
                }
            }

            axios
                .post(url, formData, config)
                .then(async response => {
                    await this.saveReviews(titleId)
                    this.redirectAfterBookSave(isNew, titleId)
                })
                .catch(error => {
                    console.error('Error occurred on image upload: ', error, error.response.data);
                    alert('Error uploading image: ' + error);
                });
        }
        else {
            const titleId = await this.saveData(isNew)
            await this.saveReviews(titleId)
            this.redirectAfterBookSave(isNew, titleId)
        }
    }

    async saveReviews(titleId) {
        let data = {
            reviews: this.state.reviews,
            titleId: titleId,
            statusEntry: this.state.statusEntry
        }

        const url = environment.baseUrl + '/api/reviews/save'

        return axios.post(url, data, this.config)
            .catch(error => {
                console.error('Error occurred on review save: ', error, error.response);
                alert('Error saving reviews: ' + error);
            });
    }

    async uploadReviews() {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + this.user.token
            }
        }

        let fileUploads = []

        this.state.reviews.forEach(review => {
            if (review.needsUploading === true) {
                console.debug('uploading: ', review.reviewPDFName, review.selectedFile)

                const formData = new FormData();
                formData.append('files', review.selectedFile)

                let upload = 
                    axios.post(`${environment.baseUrl}/api/reviews/upload`, formData, config)
                    .then(response => {
                        review.reviewPDFName = response.data.tempfile
                        return true
                    })
                    .catch(error => {
                        console.error('Error uploading review: ', error, error.response.data)
                        alert('Error uploading reviews: ' + error)
                        return false
                    })

                fileUploads.push(upload)
            }
        })
        
        return Promise.all(fileUploads)
    }

    async saveData(isNew) {
        let model = { ...this.state }
        model.contributors = this.updateContributorSortOrder(model.contributors)
        model.essays = this.collectedWorks.state.essays

        if (isNew) {
            model.titleId = 0
        }

        return axios
            .post(`${environment.baseUrl}/api/book/bookEdit`, model, this.config)
            .then(response => {
                return response.data.titleId
            })
            .catch(error => {
                console.error('Error occurred on submit: ', error, error.response.data);
                alert('Error saving book data: ' + error);
            });
    }

    redirectAfterBookSave(isNew, titleId) {
        MessageFlash.setMessage("Book data successfully saved")

        if (isNew) {
            this.props.history.replace('/admin/bookDetails/' + titleId + '?new=true')
        }
        else {
            this.props.history.goBack();
        }
    }

    updateContributorSortOrder(contributors) {
        let index = 1;
        contributors.forEach(x => x.sortOrder = index++)
        return contributors;
    }

    onSubjectChange = (subjects) => {
        this.setState({
            subjects: subjects
        })
    }

    getTitle() {
        return this.isNewBook() ? 'Add Book' : 'Edit Book'
    }

    getBackToBookDetailLink() {
        return this.isNewBook() ? '' : (
            <Link to={'#'} onClick={this.backButtonClick}>
                Back to Book Detail
            </Link>
        )
    }

    getBookStatusBlock() {
        const self = this

        const save = (data) => {
            let isAcceptingVolunteers = self.state.isAcceptingVolunteers
            if (`${self.state.bookStatusId}` !== BookStatusEnum.RBLApproved 
                && data.bookStatusId === BookStatusEnum.RBLApproved) {
                    isAcceptingVolunteers = true
            }
            else if (self.state.bookStatusId === BookStatusEnum.RBLApproved 
                && data.bookStatusId !== BookStatusEnum.RBLApproved) {
                    isAcceptingVolunteers = false
            }

            self.setState({
                bookStatusId: data.bookStatusId,
                bookStatusNote: data.note,
                isAcceptingVolunteers: isAcceptingVolunteers
            })
        }

        return this.isNewBook() ? '' : (
            <div className="row">
                <BookStatus 
                    bookStatusId={this.state.bookStatusId} 
                    note={this.state.bookStatusNote}
                    bookId={this.state.id}
                    onSave={save}
                    ref={(ref) => this.bookStatus = ref}
                />
            </div>
        )
    }

    onReviewsUpdated = (reviews) => {
        if (`${this.state.bookStatusId}` === BookStatusFilterEnum.RBLApproved 
            && reviews.length > 0) {
                this.setState({
                    reviews: reviews,
                    bookStatusId: BookStatusEnum.RBLPublished,
                    //isAcceptingVolunteers: false
                }, () => {
                    this.bookStatus.changeStatus(this.state.bookStatusId)
                })
        }
        else if (`${this.state.bookStatusId}` === BookStatusFilterEnum.RBLPublished
            && reviews.length === 0) {
                this.setState({
                    reviews: reviews,
                    bookStatusId: BookStatusEnum.RBLApproved,
                    //isAcceptingVolunteers: true
                }, () => {
                    this.bookStatus.changeStatus(this.state.bookStatusId)
                })
            }
        else {
            this.setState({
                reviews: reviews
            })
        }
    }

    getBookReviews() {
        if (`${this.state.bookStatusId}` === BookStatusFilterEnum.RBLApproved
            || `${this.state.bookStatusId}` === BookStatusFilterEnum.RBLPublished) {
                return (
                    <div className="row">
                        <BookReviews reviews={this.state.reviews} onReviewsUpdated={this.onReviewsUpdated}/>
                    </div>
                )
        }

        return ''
    }

    onCheckboxChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
    }

    handleAccountChange = (e) => {
        let account = e.target.options[e.target.selectedIndex].value

        this.setState({
            accountId: account
        })
    }

    getSourceFileDisplay() {
        if (this.isNewBook()) {
            return (
                <div className="source-file-empty">
                    &nbsp;
                </div>
            )
        }

        return (
            <div className="row col-xs-12 source-file">
                <InputField title="Source File"
                    name="sourceFile"
                    value={this.state.sourceFile}
                    onChange={this.handleInputChange}
                    readOnly
                />
            </div>
        )
    }

    getAccountSelect() {
        if (this.state.isClassicRBLImport === true) {
            return ''
        }

        return (
            <div className="row account-selector">
                <AccountSelect name="accountId"
                    Title="Bill To"
                    accounts={this.state.accounts}
                    onChange={this.handleAccountChange}
                    defaultValue={this.state.accountId}
                    required
                />
            </div>
        )
    }

    getSuppressEmailSetting() {
        if (this.state.isClassicRBLImport === true) {
            return ''
        }

        return (
            <div className="row suppress-check-box-group">
                <Checkbox name="suppressEmailNotifications" 
                    checked={this.state.suppressEmailNotifications} 
                    onChange={this.onCheckboxChange}
                    id="suppressCheckBox"
                >
                    Suppress member email notifications?
                </Checkbox>
            </div>
        )
    }

    getIsAcceptingVolunteersSetting() {
        const current = `${this.state.bookStatusId}`

        if (current !== BookStatusFilterEnum.RBLApproved 
            && current !== BookStatusFilterEnum.RBLPublished )
            {
                return ''
            }

        return (
            <div className="row volunteer-check-box-group">
                <Checkbox name="isAcceptingVolunteers" 
                    checked={this.state.isAcceptingVolunteers} 
                    onChange={this.onCheckboxChange}
                    id="volunteersCheckBox"
                >
                    Accepting Volunteers?
                </Checkbox>
            </div>
        )
    }

    render() {
        return guardAdmin(
            <Fragment>
                {getBusyIndicator(this.state.loading,
                    <div className="book-editor">
                        <ModalRoot/>
                        <ModalRootStacked/>
                        <div className="TextContainer container">
                            <div className="BackButtonContainer">
                                {this.getBackToBookDetailLink()}
                            </div>

                            <div className="title-section col-xs-11 text-center">
                                <div className="col-xs-12">
                                    <div className="book-text-container">
                                        <span className="book-text">{this.getTitle()}</span>
                                    </div>
                                </div>
                                <div className="row required required-legend col-xs-11">
                                    <span className="requiredMarker">
                                        *
                                    </span>
                                    <span className="requiredText">
                                        &nbsp;Required
                                    </span>
                                </div>
                            </div>

                            <div className="col-sm-10">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row ImageAndDetailsRow">
                                        <div className="col-xs-3 ImageSection">
                                            <div className="ConferencePaperImageContainer">
                                                {this.getImageDisplay()}
                                            </div>
                                        </div>
                                        <div className="col-xs-9 DetailsSection">
                                            <div className="DetailsContainer">
                                                <div>
                                                    <InputField title="Title"
                                                        maxLength="500"
                                                        name="title"
                                                        value={this.state.title}
                                                        onChange={this.handleInputChange}
                                                        required
                                                    />
                                                </div>
                                                <div id="sub-title">
                                                    <InputField title="Subtitle"
                                                        maxLength="255"
                                                        name="subtitle"
                                                        value={this.state.subtitle}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </div>
                                                <div>
                                                    <InputField title="Edition"
                                                        maxLength="150"
                                                        name="edition"
                                                        value={this.state.edition}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <Contributors contributors={this.state.contributors}/>
                                    </div>

                                    <div className="row">
                                        <div className="col-xs-6">
                                            <InputField title="Series Title"
                                                maxLength="255"
                                                name="seriesTitle"
                                                value={this.state.seriesTitle}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>

                                        <div className="col-xs-6">
                                            <InputField title="Series Number"
                                                maxLength="150"
                                                name="seriesNumber"
                                                value={this.state.seriesNumber}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xs-7">
                                            <InputField title="Publisher Name"
                                                maxLength="255"
                                                name="publisherName"
                                                value={this.state.publisherName}
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xs-7">
                                            <InputField title="Publisher Location"
                                                maxLength="255"
                                                name="publisherLocation"
                                                value={this.state.publisherLocation}
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xs-3">
                                            <InputField title="Copyright Year"
                                                maxLength="4"
                                                name="copyright"
                                                value={this.state.copyright}
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-xs-6">
                                            <InputField title="Number of Pages"
                                                maxLength="50"
                                                name="numberOfPages"
                                                value={this.state.numberOfPages}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <BookFormats formats={this.state.formats}/>
                                    </div>

                                    <div className="row col-xs-12">
                                        <TextField title="Description"
                                            maxLength="5000"
                                            name="description"
                                            value={this.state.description}
                                            onChange={this.handleInputChange}
                                            rows="9"
                                            required
                                        />
                                    </div>

                                    <div className="row col-xs-12">
                                        <InputField title="DOI/Permanent URL"
                                            maxLength="500"
                                            name="doiPermanentURL"
                                            value={this.state.doiPermanentURL}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>

                                    <div className="row col-xs-12">
                                        <InputField title="Keywords"
                                            maxLength="500"
                                            name="keywords"
                                            value={this.state.keywords}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>

                                    <div className="row">
                                        <ExpertiseComponent subjects={this.state.subjects} onSubjectChange={this.onSubjectChange}/>
                                    </div>

                                    <div className="row">
                                        <CollectedWorksEssays essays={this.state.essays} ref={(ref) => this.collectedWorks = ref}/>
                                    </div>

                                    {this.getSourceFileDisplay()}

                                    {this.getAccountSelect()}

                                    {this.getSuppressEmailSetting()}

                                    {this.getIsAcceptingVolunteersSetting()}

                                    {this.getBookReviews()}

                                    {this.getBookStatusBlock()}

                                    <div className="row col-xs-12 text-center">
                                        <button className="SubmitButton">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        )
    }
}
