import React, { Fragment } from 'react'
import axios from "axios";
import environment from "../../Environments/environments";
import DataCallerBaseComponent from '../../Components/Core/dataCallerBaseComponent'
import journalImage from "../../Assets/Images/book_with_image.png";
import renderHTML from 'react-render-html';

import { RoundCheckbox } from '../../Components/componentsBarrel';
import { getBusyIndicator } from '../../Helpers/busyIndicator'

import './journalNotifications.css'

export class JournalNotifications extends DataCallerBaseComponent {
    constructor(props) {
        super(props)

        this.state = {
            notifications: [],
            journals: [],
            loading: false
        }
    }

    componentDidMount() {
        if (!this.user) {
            window.location = '/'
        }

        this.setState({loading: true}, () => this.loadFromServer())
    }

    loadFromServer() {
        if (!this.user) return

        let promises = [
            this.loadJournals(),
            this.loadUserSelections()
        ]

        Promise.all(promises)
            .then(values => {
                this.buildJournalSelectedList(values[0], values[1])
                
                this.setState({
                    journals: values[0],
                    loading: false
                })
            })
    }

    loadJournals() {
        let url = `${environment.baseUrl}/api/journals/enumerate`

        return axios
            .get(url, this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.message)
                    return []
                }

                return response.data.journals
            })
            .catch(error => {
                console.error('Error getting journals: ', error, error.response);
                alert('Error Getting Journals:' + error);
                return []
            })
    }

    loadUserSelections() {
        let url = `${environment.baseUrl}/api/journalnotifications/enumerate/${this.user.sblId}`

        return axios
            .get(url, this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.message)
                    return []
                }

                return response.data.notifications.settings
            })
            .catch(error => {
                console.error('Error getting journal notifications: ', error, error.response);
                alert('Error Getting Journal notifications:' + error);
                return []
            })
    }

    buildJournalSelectedList(journals, selections) {
        selections.forEach(selection => {
            let journal = journals.find(x => x.id === selection.journalId)
            if (journal) {
                journal.selected = true
            }
        })
    }

    onCancel = (e) => {
        e.preventDefault();
        this.props.onClose()
    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.loading === false) {
            this.setState({loading: false}, () => {
                this.save()
                this.setState({loading: false})
            })
        }
    }

    async save() {
        let notifications = {
            sblId: this.user.sblId,
            settings: []
        }

        this.state.journals.forEach(journal => {
            if (journal.selected === true) {
                notifications.settings.push({
                    sblId: this.user.sblId,
                    journalId: journal.id
                })
            }
        })

        let url = `${environment.baseUrl}/api/journalnotifications/save`
        //debugger;
        await axios
            .post(url, notifications, this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.message)
                    return
                }

                alert('Journal notifications saved successfully.')
                
                this.props.onClose()
            })
            .catch(error => {
                console.error('Error saving notifications: ', error, error.response);
                alert('Error Saving Notifications: ' + error);
            })
    }

    toggleCheck = (e, index) => {
        this.state.journals[index].selected = !this.state.journals[index].selected
    }

    listJournals() {
        return (

            <div className="notifications-area">
                {this.state.journals.map((journal, index) => {
                    if(journal.isActive){
                        return (
                            <Fragment key={index}>
                                <RoundCheckbox
                                    text={this.getJournalDisplayText(journal)}
                                    selected={journal.selected}
                                    onChange={(e) => this.toggleCheck(e, index)}
                                />
                                <hr />
                            </Fragment>
                        )
                    }
                })}
            </div>
        )
    }

    addProtocolText(url) {
        if(!/http|https/.test(url)){
            return "https://" + url;
        }
        return url;
    }

    displayISSN(journal) {
        const issn = 
            journal.printISSN === null && journal.onlineISSN === null
            ? ""
            : (<strong>ISSN: </strong>)

        return (
            <span className="journal-issn-content">
                {issn}

                {
                    journal.printISSN !== null
                    ? <span>{journal.printISSN} (Print)</span>
                    : ""
                }
                {
                    journal.onlineISSN !== null
                    ? journal.printISSN !== null 
                        ? <span className="ml-20">{journal.onlineISSN} (Online)</span>
                        : <span>{journal.onlineISSN} (Online)</span>
                    : ""
                }
            </span>  
        )
    }

    displayUrlProtocol(journal){
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator 

        if (pattern.test(journal.url)) {
            return <a target="_blank" rel="noopener noreferrer" href={this.addProtocolText(journal.url)}>{journal.title}</a> 
        }
        else {
            return <span>{journal.title}</span>
        }
    }


    getJournalDisplayText(journal) {
        const imageUrl = (journal.imageUrl !== null) ? journal.imageUrl : journalImage
        let issuesPerYear = journal.issuesPerYear;
        let issuesPerYearTxt = "issues per year";

        return (
            <Fragment>
                <div className="journal-content-container">
                    <div className="journal-content-left"><img className="journal-image" src={imageUrl} /></div>
                    <div className="journal-content-right">
                        <p>
                            <span id="title">
                                {this.displayUrlProtocol(journal)}
                            </span>              
    
                            <span className="journal-publisher"> ({journal.publisher})</span>
                        </p>

                        <p><strong>Publication Frequency</strong>
                            <span className="journal-issn-content"><span>: </span>  
                            {issuesPerYear === 0 || issuesPerYear === "" || issuesPerYear === null 
                                ? <span>0 {issuesPerYearTxt}</span>
                                : 
                                    issuesPerYear === 1 
                                    ?<span>{journal.issuesPerYear} {issuesPerYearTxt = "issue per year"}</span>
                                    :<span>{journal.issuesPerYear} {issuesPerYearTxt}</span>                                
                            }
                            
                            </span>
                        </p>

                        <p> 
                            {this.displayISSN(journal)}            
                        </p> 

                        <div className="journal-description">
                            {renderHTML('<div>' + journal.description + '</div>')}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    render() {
        return (
            <Fragment>
                {getBusyIndicator(this.state.loading, 
                    <div className="journal-notifications-page">
                        <div className="notifications-text-container">
                            <span className="notifications-text">Manage Journal Notifications</span>
                        </div>

                        <div className="journal-notifications-description">
                            <label>
                                Use this screen to specify your journals of interest. We’ll notify you when journal issues matching your selections are published. Such notifications will include tables of contents.
                            </label>
                        </div>

                        <form onSubmit={this.handleSubmit}>
                            {this.listJournals()}

                            <div>
                                <div className="text-center button-group">
                                    <button disabled={this.state.loading} className="SubmitButton">Submit</button>
                                    <button disabled={this.state.loading} className="secondary-button" onClick={this.onCancel}>Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </Fragment>
        )
    }
}
