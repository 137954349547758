import React, { Fragment } from 'react'
import axios from "axios";
import environment from "../../Environments/environments";
import ModalRoot from "../../Containers/Login/loginRoot"
import ReactExport from "react-data-export"
import DatePicker from 'react-datepicker'
import ReactTooltip from 'react-tooltip'

import { AccountingExportHistoryModal } from './accountingExportHistoryModal'
import { DefaultConfig } from '../../Helpers/defaultConfigHelper';
import { guardAdmin } from '../Admin/admin-base'
import { getBusyIndicator } from '../../Helpers/busyIndicator'
import { ModalConsumer } from '../../Containers/Login/loginContext'
import { AccountSelect } from '../componentsBarrel'

import { CustomDateInput } from '../componentsBarrel'

import "react-datepicker/dist/react-datepicker.css";
import './accountingExport.css'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class AccountingExport extends React.Component {
    constructor(props) {
        super(props)

        const user = (typeof sessionStorage != 'undefined') ? JSON.parse(sessionStorage.getItem('loggedInUser')) : {}

        if (!user || user === null) {
            window.location = '/'
            return
        }

        const userName = user.firstName + ' ' + user.lastName

        this.config = DefaultConfig.getDefaultConfig()

        let date = new Date()
        let today = `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`

        this.state = {
            accounts: [],
            loading: false,
            userName: userName,
            summary: [
                {label: 'Publisher', value: ''},
                {label: "Today's date", value: today},
                {label: 'Date Range', value: ''},
                {label: 'Billing Contact', value: ''},
                {label: 'Billing Email', value: ''},
                {label: 'Billing Telephone', value: ''},
                {label: 'Address Line 1', value: ''},
                {label: 'Address Line 2', value: ''},
                {label: 'City, State/Province', value: ''},
                {label: 'Postal Code', value: ''},
                {label: 'Country', value: ''},
            ]
        }
    }

    componentDidMount() {
        let footer = document.getElementById('Footer');
        footer.style.position = 'relative';

        this.setState({loading: true}, () => this.loadDataFromServer())
    }

    loadDataFromServer() {
        let fromDate = new Date()
        let toDate = new Date()

        fromDate.setDate(fromDate.getDate())
        toDate.setDate(toDate.getDate())

        this.setfromDate(fromDate)
        this.settoDate(toDate)

        axios
            .get(environment.baseUrl + '/api/accounts/enumerate', this.config)
            .then(response => {
                if (response.data.error) {
                    alert(response.data.error)
                    return;
                }

                let accounts = response.data.accounts

                //console.debug('ACCOUNTS: ', accounts)

                this.setState({
                    accounts: accounts,
                    loading: false
                })
            })
            .catch(error => {
                console.error('Error loading data: ', error, error.response);
                alert('Error Loading Data');

                this.setState({
                    accounts: [],
                    loading: false
                })
            })
    }

    setfromDate = (date) => {
        date.setHours(0, 0, 0, 0)

        this.setState({
            fromDate: date,
            fromDateText: date.toLocaleString('en-US', options) + ' 12:00 AM'
        })
    }

    settoDate = (date) => {
        date.setHours(23, 59, 59, 999)

        this.setState({
            toDate: new Date(date),
            toDateText: date.toLocaleString('en-US', options) + ' 11:59:59.999 PM'
        })
    }

    onCancel = (e) => {
        window.location = '/admin/reports'
    }

    getExportHistoryLink(showModal) {
        return (
            <a className="export-history-link" onClick={(e) => this.onExportHistory(e, showModal)}>Export History</a>
        )
    }

    getExportHistoryBlock() {
        return (
            <div>
                <span>
                    <ModalConsumer>
                        {({ showModal }) => (
                            <Fragment>
                                {this.getExportHistoryLink(showModal)}
                            </Fragment>
                        )}
                    </ModalConsumer>
                </span>
            </div>
        )
    }

    onExportHistory = (e, showModal) => {
        showModal(AccountingExportHistoryModal, {})
    }

    ellipsisFormat(longText) {
        if (!longText || longText.length <= 25) {
            return longText
        }

        return longText.substring(0, 25) + '...'
    }

    formatTooltip(text) {
        const tipPrefix = "<p style='text-align: left; width: 600px'>"
        const tipSuffix = '</p>'

        return tipPrefix + text + tipSuffix
    }

    calculateDisplayDate(item) {
        if (!item.displayDate) {
            return ''
        }

        let date = new Date(item.displayDate)
        let month = (date.getMonth()+1).toLocaleString('en-us', {minimumIntegerDigits: 2})
        let day = (date.getDate()).toLocaleString('en-us', {minimumIntegerDigits: 2})

        return `${month}/${day}/${date.getFullYear()}`
    }

    getDataDisplay() {
        if (!this.state.data) {
            return ''
        }

        return (
            <Fragment>
                <div className="data-display">
                    <hr/>
                    <div>
                        <a className="export-link" onClick={this.onDownload}>Export to Excel</a>
                        {this.getExcelFunctionalButton()}
                    </div>
                    <div className="data-table">
                        <table id="data-table">
                            <thead>
                                <tr className="table-head">
                                    <th>Import File</th>
                                    <th>Display Date</th>
                                    <th>ISBN</th>
                                    <th>Title</th>
                                    <th>Subtitle</th>
                                    <th>Publisher</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.data.books.map((item, index) => {
                                    item.dateDisplay = this.calculateDisplayDate(item)

                                    return (
                                        <tr className="table-row" key={index}>
                                            <td>{item.importFile}</td>
                                            <td>{item.dateDisplay}</td>
                                            <td>{item.isbn}</td>
                                            <td>{item.title}</td>
                                            <td>{item.subTitle}</td>
                                            <td>{item.publisher}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Fragment>
        )
    }

    formatDate(date) {
        return new Date(date).toLocaleDateString()
    }

    handleAccountChange = (e) => {
        e.preventDefault()

        let option = e.target.options[e.target.selectedIndex]
        let account = option.value
        let name = option.attributes.name.nodeValue

        this.setState({
            accountId: account,
            publisher: name
        })
    }

    validate() {
        if (this.state.toDate < this.state.fromDate) {
            alert('To Date cannot be prior to From Date')
            return false
        }

        let tomorrow = new Date()
        tomorrow.setHours(0, 0, 0, 0)
        tomorrow.setDate(tomorrow.getDate() + 1)

        if(this.state.toDate > tomorrow) {
            alert('To Date cannot be after current date')
            return false
        }

        if (!this.state.accountId || this.state.accountId === 0) {
            alert('Please select an account.')
            return
        }

        return true
    }

    onSubmit = (e) => {
        this.setState({data: null}, () => this.updateDataDisplay())
    }

    updateDataDisplay() {
        if (this.validate()) {
            this.setState({loading: true}, () => this.processData())
        }
    }

    processData() {
        let data = {
            fromDate: this.state.fromDate.toLocaleString(),
            toDate: this.state.toDate.toLocaleString(),
            accountId: this.state.accountId
        }

        let url = `${environment.baseUrl}/api/admin/accountingExport`

        axios.post(url, data, this.config)
            .then(response => {
                if (response.data.success === false) {
                    alert('Error processing accounting export data: ' + response.data.error)
                    this.setState({loading: false})
                    return
                }

                //console.debug('Export Data: ', response.data.exportData)

                this.setState({
                    data: response.data.exportData,
                    loading: false
                })
            })
            .catch(error => {
                this.setState({loading: false})
                console.error('Error getting Accounting Export data: ', error)
                alert('Error getting Accounting Export data: ' + error)
            })
    }

    getExcelFunctionalButton() {
        const multiDataSet = [
            {
                columns: [
                    {title: "", width: {wpx: 130}},//pixels width 
                    {title: ""}
                ],
                data: [
                    [
                        {value: this.state.summary[0].label, style: {font: {bold: true}}},
                        {value: this.state.summary[0].value}
                    ],
                    [
                        {value: this.state.summary[1].label, style: {font: {bold: true}}},
                        {value: this.state.summary[1].value}
                    ],
                    [
                        {value: this.state.summary[2].label, style: {font: {bold: true}}},
                        {value: this.state.summary[2].value}
                    ],
                    [
                        {value: this.state.summary[3].label, style: {font: {bold: true}}},
                        {value: this.state.summary[3].value}
                    ],
                    [
                        {value: this.state.summary[4].label, style: {font: {bold: true}}},
                        {value: this.state.summary[4].value}
                    ],
                    [
                        {value: this.state.summary[5].label, style: {font: {bold: true}}},
                        {value: this.state.summary[5].value}
                    ],
                    [
                        {value: this.state.summary[6].label, style: {font: {bold: true}}},
                        {value: this.state.summary[6].value}
                    ],
                    [
                        {value: this.state.summary[7].label, style: {font: {bold: true}}},
                        {value: this.state.summary[7].value}
                    ],
                    [
                        {value: this.state.summary[8].label, style: {font: {bold: true}}},
                        {value: this.state.summary[8].value}
                    ],
                    [
                        {value: this.state.summary[9].label, style: {font: {bold: true}}},
                        {value: this.state.summary[9].value}
                    ],
                    [
                        {value: this.state.summary[10].label, style: {font: {bold: true}}},
                        {value: this.state.summary[10].value}
                    ],
                ]
            }
        ];

        return (
            <ExcelFile element={this.getExcelButton()} filename="AccountingExport">
                <ExcelSheet dataSet={multiDataSet} name="Publisher" />
                <ExcelSheet data={this.state.data.books} name="Accounting Export">
                    <ExcelColumn label="Import File" value="importFile"/>
                    <ExcelColumn label="Display Date" value="dateDisplay"/>
                    <ExcelColumn label="ISBN" value="isbn"/>
                    <ExcelColumn label="Title" value="title"/>
                    <ExcelColumn label="Subtitle" value="subTitle"/>
                    <ExcelColumn label="Publisher" value="publisher"/>
                </ExcelSheet>
            </ExcelFile>
        )
    }

    getExcelButton() {
        return (
            <button
                id = "table-xls-button"
                className = "download-table-xls-button"
            ></button>
        )
    }

    onDownload = (e) => {
        let now = new Date()
        now.setHours(0, 0, 0, 0)

        let body = {
            exportDate: now.toLocaleDateString(),
            adminUser: this.state.userName,
            fromDate: this.state.fromDate.toLocaleString(),
            toDate: this.state.toDate.toLocaleString(),
            publisher: this.state.publisher,
            accountId: this.state.accountId
        }

        let url = `${environment.baseUrl}/api/admin/accounting-export-history`

        axios.post(url, body, this.config)
            .then(response => {
                if (response.data.success === false) {
                    alert('Error saving history: ' + response.data.message)
                    return
                }

                //console.debug('account: ', response.data.account)

                let summary = this.state.summary
                let account = response.data.account

                let fromDate = (new Date(this.state.fromDate)).toISOString().split('T')[0]
                let toDate = (new Date(this.state.toDate)).toISOString().split('T')[0]

                summary[0].value = account.name
                summary[2].value = `${fromDate} to ${toDate}`
                summary[3].value = `${account.firstName} ${account.lastName}`
                summary[4].value = account.email
                summary[5].value = account.telephone

                this.populateBillingAddress(summary, account)

                this.setState({
                    summary: summary
                }, () => {
                    let button = document.getElementById('table-xls-button')
                    button.click()
                })
            })
            .catch(error => {
                console.error('Error saving export history', error)
                alert('Error saving export history: ' + error)
            })
    }

    populateBillingAddress(summary, account) {
        summary[6].value = account.address1 ? account.address1 : ''
        summary[7].value = account.address2 ? account.address2 : ''

        let cityState = account.city ? account.city : ''
        if (account.province) {
            if (cityState !== '') {
                cityState += ', '
            }
            cityState += account.province
        }

        if (account.stateId !== 0) {
            if (cityState !== '') {
                cityState += ', '
            }
            cityState += account.state.name
        }

        summary[8].value = cityState

        summary[9].value = account.postalCode ? account.postalCode : ''
        summary[10].value = account.country.countryName
    }

    getYesterday() {
        let yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        return yesterday
    }

    filtertoDate = (date, fromDate) => {
        let yesterday = this.getYesterday()
        return (date <= yesterday) && date >= fromDate 
    }

    filterfromDate = (date) => {
        let yesterday = this.getYesterday()
        return date <= yesterday
    }

    render() {
        return guardAdmin(
            <Fragment>
                {getBusyIndicator(this.state.loading, 
                    <div className="accounting-export">
                        <ModalRoot/>
                        <ReactTooltip multiline={true}/>
                        <div className="TextContainer container">
                            <div className="title-section col-xs-12 text-center">
                                <div className="col-xs-12">
                                    <div className="rbl-export-container">
                                        <span className="title-text">Accounting Export</span>
                                    </div>

                                    <div className="export-content">
                                        <div className="RBLExportLink">
                                            {this.getExportHistoryBlock()}
                                        </div>

                                        <div className="row account-select">
                                            <div className="col-xs-4">
                                                <AccountSelect name="account"
                                                    Title="Publisher"
                                                    accounts={this.state.accounts}
                                                    onChange={this.handleAccountChange}
                                                    defaultValue={this.state.accountId}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xs-4"><strong>From</strong></div>
                                            <div className="col-xs-5"><strong>To</strong></div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xs-4">
                                                <DatePicker
                                                    selected={this.state.fromDate}
                                                    customInput={<CustomDateInput formatted={this.state.fromDateText}/>}
                                                    onChange={date => this.setfromDate(date)}
                                                    filterDate={this.filterfromDate}
                                                />
                                            </div>
                                            <div className="col-xs-5">
                                                <DatePicker
                                                    selected={this.state.toDate}
                                                    customInput={<CustomDateInput formatted={this.state.toDateText}/>}
                                                    onChange={date => this.settoDate(date)}
                                                    filterDate={date => this.filtertoDate(date, this.state.fromDate)}
                                                />
                                            </div>
                                        </div>

                                        <div className="button-section row col-xs-13 text-center">
                                            <button className="SubmitButton" onClick={this.onSubmit}>Submit</button>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <button className="secondary-button" onClick={this.onCancel}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.getDataDisplay()}
                    </div>
                )}
            </Fragment>
        )
    }
}

const options = { 
    timeZone: "America/New_York", 
    hour12: true,
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    //hour: 'numeric',
    //second: 'numeric',
    //minute: 'numeric'
};

function exportTableToExcel(tableID, filename = ''){
    var downloadLink;
    var dataType = 'application/vnd.ms-excel';
    var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
    
    // Specify file name
    filename = filename?filename+'.xls':'excel_data.xls';
    
    // Create download link element
    downloadLink = document.createElement("a");
    
    document.body.appendChild(downloadLink);
    
    if(navigator.msSaveOrOpenBlob){
        var blob = new Blob(['\ufeff', tableHTML], {
            type: dataType
        });
        navigator.msSaveOrOpenBlob( blob, filename);
    }else{
        // Create a link to the file
        downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
    
        // Setting the file name
        downloadLink.download = filename;
        
        //triggering the function
        downloadLink.click();
    }
}

