import React from 'react'
import axios from "axios";
import environment from "../../Environments/environments";
import { guardAdmin } from '../Admin/admin-base'
import { AdminButton } from '../AdminButton/adminButton'
import { BusyIndicator } from '../BusyIndicator/busyIndicator'
import { MessageFlash } from '../../Helpers/messageFlashHelper';
import { DefaultConfig } from '../../Helpers/defaultConfigHelper';

import './adminUsers.css'

export class AdminUsers extends React.Component {
    constructor(props) {
        super(props);

        this.config = DefaultConfig.getDefaultConfig()

        this.state = {
            staff: [],
            admins: [],
            selectedAdmin: 0,
            selectedStaff: 0,
            busy: true
        }
    }

    componentDidMount() {
        axios
            .get(`${environment.baseUrl}/api/admin/staff`, this.config)
            .then(response => {
                this.updateUsers(response.data);
            })
            .catch(error => {
                console.error('Error Occurred on User Get: ', error)
                alert(error);
                this.setState({ busy: false })
            });
    }

    updateUsers = (data) => {
        let staff = [];
        let admins = [];

        data.forEach((item) => {
            if (item.isAdminUser === true) admins.push(item)
            else staff.push(item);
        })

        this.setState({
            staff: staff,
            admins: admins,
            busy: false
        })
    }

    getUsersDisplay() {
        return (
            <div>
                <div className="admin-controls">
                    <div className="block">
                        <label>SBL Staff</label>
                        {this.getStaffDisplay()}
                    </div>

                    <div className="blockButtons">
                        <i className="admin-arrow fas fa-arrow-circle-right"
                            onClick={this.handleAddAdminButton}></i>
                        <br /><br /><br />
                        <i className="admin-arrow fas fa-arrow-circle-left"
                            onClick={this.handleRemoveAdminButton}></i>
                    </div>

                    <div className="block">
                        <label>Administrators</label>
                        {this.getAdminDisplay()}
                    </div>
                </div>

                <div className="admin-controls">
                    <div className="block admin-button-container">
                        <div className="adminSubmit">
                            <AdminButton onClick={this.onSubmit}>Submit</AdminButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getStaffDisplay() {
        return (
            <select
                className="user-list"
                name="staffList"
                id="staffList"
                size="9"
                onDoubleClick={this.handleAddAdmin}
                onChange={(e) => this.setState({ selectedStaff: e.target.value })}
                value={this.state.selectedStaff}>

                {this.state.staff.map((item, index) => {
                    return <option key={index} value={index}>
                        {item.lastName}, {item.firstName}
                    </option>
                })}
            </select>
        )
    }

    getAdminDisplay() {
        return (
            <select
                className="user-list"
                name="adminList"
                id="adminList"
                size="9"
                onDoubleClick={this.handleRemoveAdmin}
                onChange={(e) => this.setState({ selectedAdmin: e.target.value })}
                value={this.state.selectedAdmin}>

                {this.state.admins.map((item, index) => {
                    return (
                        <option key={index} value={index}>
                            {item.lastName}, {item.firstName}
                        </option>
                    )
                })}

            </select>
        )
    }

    handleAddAdminButton = (e) => {
        let staffList = document.getElementById('staffList');
        if (staffList.selectedOptions.length > 0) {
            this.addToAdmin(staffList.selectedOptions[0].index);
        }
    }

    handleRemoveAdminButton = (e) => {
        let adminList = document.getElementById('adminList');
        if (adminList.selectedOptions.length > 0) {
            this.removeFromAdmin(adminList.selectedOptions[0].index)
        }
    }

    handleAddAdmin = (e) => {
        this.addToAdmin(e.target.index)
    }

    addToAdmin(index) {
        let member = this.state.staff.splice(index, 1)[0];
        let newIndex = this.findSortedIndex(this.state.admins, member);
        this.state.admins.splice(newIndex, 0, member);
        this.setState({
            selectedAdmin: newIndex,
            selectedStaff: 0
        })
    }

    handleRemoveAdmin = (e) => {
        this.removeFromAdmin(e.target.index)
    }

    removeFromAdmin(index) {
        let member = this.state.admins.splice(index, 1)[0];
        let newIndex = this.findSortedIndex(this.state.staff, member);
        this.state.staff.splice(newIndex, 0, member);
        this.setState({
            selectedStaff: newIndex,
            selectedAdmin: 0
        });
    }

    findSortedIndex(array, item) {
        let index = 0;
        for (let i = 0; i < array.length; ++i) {
            index = i;
            let compareResult = item.lastName.localeCompare(array[i].lastName);

            if (compareResult < 1 ||
                (compareResult === 0 && item.firstName.localeCompare(array[i].firstName) < 1)) {
                return index;
            }
        }

        return index + 1;
    }

    onSubmit = (e) => {
        console.log('onSubmit');
        let model = {
            AdminStaff: this.state.admins,
            NonAdminStaff: this.state.staff
        }

        axios.post(`${environment.baseUrl}/api/admin/staff`, model, this.config)
            .then(response => {
                MessageFlash.setMessage('User Changes Saved Successfully')
                window.location = "/admin/system"
            })
            .catch(error => {
                console.error('Error Occurred on Saved: ', error);
                alert(error);
            })
    }

    render() {
        return guardAdmin(
            this.state.busy ? <BusyIndicator busy={true}></BusyIndicator> :
                <div className="adminUsers">
                    <div className="admin-title">
                        Admin User Management
                        </div>
                    <div className="Users">
                        {this.getUsersDisplay()}
                    </div>
                </div>
        )
    }
}
