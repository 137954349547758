import * as React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/Images/SBLCentral-Logo-Resize.jpg";
import questionHelpLogo from "../../Assets/Images/questionmark-icon.png";
import { DropdownButton } from "react-bootstrap";
import { MenuItem } from "react-bootstrap";
import { ButtonToolbar } from "react-bootstrap";

import "./headerStyles.css";
import { Login } from "../../Containers/Login/login";
import { ModalConsumer } from "../../Containers/Login/loginContext";
import ModalRoot from "../../Containers/Login/loginRoot";

class Header extends React.Component {
  constructor(props) {
    super(props);

    var loggedInUser = {
      errorText: "",
      isAuthenticated: false,
      firstName: "",
      lastName: "",
      sblId: "",
      isAdminUser: false
    };

    this.state = {
      loggedInUser: loggedInUser
    }
  }

  async componentDidMount() {
    if (sessionStorage.getItem("loggedInUser")) {
      let loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser"));

      this.setState({
        loggedInUser: loggedInUser
      });
    }
  }

  onLoginClick = () => {
    this.showModal();

    if (sessionStorage.getItem("loggedInUser")) {
      this.setState({
        loggedInUser: JSON.parse(sessionStorage.getItem("loggedInUser"))
      });

      console.log('Updated user: ', this.state.loggedInUser);
    }
  };

  onLogoutClick = () => {
    this.setState(prevState => ({
      loggedInUser: {
        ...prevState.loggedInUser,
        errorText: "",
        isAuthenticated: false,
        isAdminUser: false,
        firstName: "",
        lastName: "",
        sblId: ""
      }
    }), () => {
      sessionStorage.setItem("loggedInUser", JSON.stringify(this.state.loggedInUser));
      //Redirect to home page
      window.location.assign("/home");
    });
  };

  returnSignIn = () => {
    return <div>{this.returnSignInBlock(this.state.loggedInUser)}</div>;
  };

  calculateUserName = (user) => {
    if (user.firstName && user.lastName) {
      return user.firstName + " " + user.lastName;
    }
    else if ( user.firstName ) {
      return user.firstName;
    }
    else if ( user.lastName) {
      return user.lastName;
    }

    return "Member";
  }

  returnSignInBlock(loggedInUser) {
    if (loggedInUser && loggedInUser.isAuthenticated === true) {
      return (
        <div className="LogInNameContainer nav navbar-right">
          <ButtonToolbar>
            <DropdownButton
              title={this.calculateUserName(loggedInUser)}
              id="dropdown-size-medium"
              className="LoginUserText"
            >
              {this.getNotificationsMenu(loggedInUser)}
              {this.getJournalNotificationsMenu(loggedInUser)}
              {this.getAdminMenu(loggedInUser)}
              <MenuItem eventKey="allFilter" onClick={this.onLogoutClick}>
                Sign Out
              </MenuItem>
            </DropdownButton>
          </ButtonToolbar>
        </div>
      );
    } else {
      return (
        <div className="SignInButtonContainer nav navbar-right">
          <ModalRoot />
          <ModalConsumer>
            {({ showModal }) => (
              <Fragment>
                <button
                  id="sign-in-button"
                  className="SignInButton"
                  onClick={() => showModal(Login)}
                >
                  Sign In
                </button>
              </Fragment>
            )}
          </ModalConsumer>
        </div>
      );
    }
  }

  getAdminMenu(loggedInUser) {
    if(loggedInUser.isAdminUser) {
      return (
        <MenuItem eventKey="admin" onClick={this.onAdminClick} >
          SBLC Admin
        </MenuItem>
      );
    }
  }

  getNotificationsMenu(loggedInUser) {
    return (
      <Fragment>
        <MenuItem eventKey="notifications" onClick={(e) => window.location = '/home/notifications'} >
          Manage Book Notifications
        </MenuItem>
      </Fragment>
    )
  }

  getJournalNotificationsMenu(loggedInUser) {
    return (
      <MenuItem eventKey="journalnotifications" onClick={(e) => window.location = '/home/journalNotifications'}>
        Manage Journal Notifications
      </MenuItem>
    )
  }

  onAdminClick = () => {
    window.location = '/admin';
  }

  render() {
    return (
      <nav id="header" className="Header navbar navbar-default">
        <div className="logoContainer navbar-header navbar-left">
          <Link to={`/`} replace className="navbar-brand">
            <img className="logo-image" src={logo} alt="sbl logo" />
          </Link>
        </div>
        
        <div id="helpaAndLoginContainer">
          <div className="HelpLinkContainer nav navbar-right">
            <Link to={`/help`} className="HelpImage">
              <img
                className="logo-image"
                src={questionHelpLogo}
                alt="Help"
                title="Help"
              />
            </Link>
          </div>

          {this.returnSignIn(this.state.loggedInUser)}
        </div>
      </nav>
    );
  }
}

export { Header };
