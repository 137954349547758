import React, { Fragment } from 'react'
import axios from "axios";
import environment from "../../Environments/environments";
import ModalRoot, {ModalRootStacked} from "../../Containers/Login/loginRoot";
import DataCallerBaseComponent from '../Core/dataCallerBaseComponent'
import journalImage from "../../Assets/Images/book_with_image.png";
import { Checkbox } from 'react-bootstrap'
import { guardAdmin } from '../Admin/admin-base'
import { InputField, TextField } from '../componentsBarrel'
import { getBusyIndicator } from '../../Helpers/busyIndicator'
import { MessageFlash } from '../../Helpers/messageFlashHelper'
import './userDetail.css'

export class UserDetail extends DataCallerBaseComponent {
    constructor(props) {
        super(props)

        let id = this.props.match.params.Id

        this.state = {
            id: id === 'new' ? 0 : id,
            isNew: (id === 'new'),
            firstName: '',
            lastName: '',
            email: '',
            loading: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        let footer = document.getElementById('Footer');
        footer.style.position = 'relative';

        this.setState({loading: true}, () => this.loadFromServer());
    }

    loadFromServer() {
        let promises = [
            this.getUsers(),
        ]

        Promise.all(promises)
            .then(values => {
                this.loadValues(values[0])
                
                this.setState({
                    adminusers: values[0],
                    loading: false
                })
            })
    }

    getUsers() {
        if (this.state.isNew === false) {
            let url = `${environment.baseUrl}/api/adminusers/get/${this.state.id}` 
            return axios
                .get(url, this.axiosHeaders)
                .then(response => {
                    if (response.data.success === false) {
                        alert(response.data.message)
                        return {};
                    }

                    return response.data
                })
                .catch(error => {
                    console.error('Error loading admin user: ', error, error.response);
                    alert('Error Loading admin user');

                    return {}
                })
        }

        return {}
    }

    loadValues(adminUser) {
        this.setState({
            id: adminUser.id,
            firstName: adminUser.firstName ? adminUser.firstName : '',
            lastName: adminUser.lastName ? adminUser.lastName : '',
            email: adminUser.email ? adminUser.email : '',
            loading: false
        })
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeNumericOnly = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, '')
        value = value.toUpperCase()
        this.setState({[e.target.name]: value})
    }

    onCancel = (e) => {
        e.preventDefault();
        this.props.history.goBack();
    }

    onDelete = (e) => {
        e.preventDefault();

        if (!window.confirm('Are you sure you want to delete this user?')) {
            return false;
        }
        let model = {...this.state}
        
        axios
            //.delete(environment.baseUrl + '/api/adminusers/delete/' + this.state.id, this.axiosHeaders)
            .post(environment.baseUrl + '/api/adminusers/delete', model, this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.message)
                    return
                }

                MessageFlash.setMessage('User deleted')
                this.props.history.goBack();
            })
            .catch(error => {
                console.error('Error deleting user: ', error, error.response);
                alert('Error Deleting User');
            })
    }

    getDeleteButton() {
        if (this.state.isNew === true) {
            return ''
        }

        return (
            <button className="secondary-button" onClick={this.onDelete}>Delete</button>
        )
    }

    validateForm() {
        if (this.state.firstName === null || this.state.firstName.trim() === '') {
            alert('Please enter a first name.')
            return false
        }

        if (this.state.lastName === null || this.state.lastName.trim() === '') {
            alert('Please enter a last name.')
            return false
        }

        if (!this.state.email || this.state.email.trim() === '') {
            alert('Please enter an email.')
            return false
        }

        return true
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        if (!this.validateForm()) {
            return false;
        }

        const adminUserId = await this.saveData();

        if (adminUserId === -1) {
            return false;
        }

        if (!this.state.selectedFile) {
            MessageFlash.setMessage('User Saved Successfully')
            this.props.history.goBack();
            return
        }
    }

    async saveData() {
        let model = {...this.state}

        return axios
            .post(environment.baseUrl + '/api/adminusers/save', model, this.axiosHeaders)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.message)
                    return -1
                }

                return response.data
            })
            .catch(error => {
                console.error('Error saving admin user: ', error, error.response);
                alert('Error Saving Admin User');
            })
    }

    render() {
        let header = this.state.isNew === true ? 'Add Admin User' : 'Edit Admin User'

        return guardAdmin(
            <Fragment>
                {getBusyIndicator(this.state.loading,
                    <div className="user-detail">
                        <ModalRoot/>
                        <ModalRootStacked/>
                        <div className="TextContainer container">
                            <div className="title-section col-xs-12 text-center">
                                <div className="col-xs-12">
                                    <div className="user-text-container">
                                        <span className="user-text">{header}</span>
                                    </div>
                                </div>
                                <div className="row required">
                                    <div className="required-legend col-xs-12">
                                        <span className="requiredMarker">
                                            *
                                        </span>
                                        <span className="requiredText">
                                            &nbsp;Required
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-10">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row col-xs-8">
                                        <InputField title="First Name"
                                            maxLength="4000"
                                            name="firstName"
                                            value={this.state.firstName}
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>

                                    <div className="row col-xs-8">
                                        <InputField title="Last Name"
                                            maxLength="4000"
                                            name="lastName"
                                            value={this.state.lastName}
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>

                                    <div className="row col-xs-8">
                                        <InputField title="Email Address"
                                            maxLength="4000"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleInputChange}
                                            required
                                        />
                                    </div>

                                    <div className="col-xs-12">
                                        <div className="row col-xs-12 text-center button-group">
                                            <button className="SubmitButton">Submit</button>
                                            <button className="secondary-button" onClick={this.onCancel}>Cancel</button>
                                            {this.getDeleteButton()}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        )
    }
}
