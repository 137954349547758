import React from 'react'
import axios from 'axios'
import environment from "../../Environments/environments";
import { Checkbox } from 'react-bootstrap';
import { AdminButton } from '../../Components/AdminButton/adminButton'
import { DefaultConfig } from '../../Helpers/defaultConfigHelper';

import './notificationTest.css'

export class NotificationTest extends React.Component {
    constructor(props) {
        super(props)

        let alternate = environment.defaultNotificationEmail ? environment.defaultNotificationEmail : ''
        let user = JSON.parse(sessionStorage.getItem("loggedInUser"));
        this.config = DefaultConfig.getDefaultConfig()

        this.state = {
            recipient: '',
            alternate: alternate,
            bccAdmin: false,
            bccEmail: user.emailAddress
        }
    }

    componentDidMount() {
        let footer = document.getElementById('Footer');
        footer.style.position = 'relative';
    }

    onSubmit = async (e) => {
        if (this.validate()) {
            if (window.confirm('Are you sure you wish to trigger the weekly notification process now?')) {
                await this.triggerNotificationProcess()
            }
        }
    }

    async triggerNotificationProcess() {
        let data = {
            memberEmail: this.state.recipient,
            redirectEmail: this.state.alternate,
            bccEmail: this.state.bccAdmin ? this.state.bccEmail : ''
        }
        
        let uri = `${environment.baseUrl}/api/trigger-notifications`

        return axios.post(uri, data, this.config)
            .then(response => {
                if (response.data.success === false) {
                    alert(response.data.error)
                    return
                }

                alert('Process returned successfully - If any emails were found to send, they seem to have been processed successfully.')
            })
            .catch(error => {
                console.error('Error calling notification trigger: ', error, error.message)
                alert('Error calling notification trigger: ' + error.message)
            })
    }

    validate() {
        if (this.state.recipient === '') {
            alert('A member email address is required.')
            return false
        }

        const emailValidator = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        let isEmailValid = emailValidator.test(this.state.recipient)

        if (isEmailValid === false) {
            alert('Email address is not valid. Please try again.')
            return false
        }

        if (this.state.alternate !== '') {
            if (emailValidator.test(this.state.alternate) === false) {
                alert('Alternate email address is not valid.  Please try again.')
                return false
            }
        }

        return true
    }

    onReset = (e) => {
        let message = "WARNING! This will reset dates of underlying data so that no books or reviews are new enough to trigger a notification email.\n\nAre you sure you wish to do this?"

        if (window.confirm(message)) {
            if (window.confirm('WARNING!  This cannot be undone.  Continue?')) {
                axios.post(`${environment.baseUrl}/api/reset-notifications/kendall`, this.config)
                .then(response => {
                    if (response.data.error) {
                        alert(response.data.error)
                        return
                    }

                    alert('Reset completed successfully')
                })
                .catch(error => {
                    console.error('Error calling notification reset: ', error, error.message)
                    alert('Error calling notification reset: ' + error.message)
                })
            }
        }
    }

    handleInputChanges = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onCheckboxChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
    }

    onTriggerAuto = (e, special) => {
        let message = 'This will trigger the notification process but induce a failure to test the confirmation email.  Continue?'

        if (window.confirm(message)) {
            axios.post(`${environment.baseUrl}/api/autotrigger-notifications/${special}`, null, this.config)
            .then(response => {
                if (response.data.error) {
                    alert(response.data.error)
                    return
                }

                alert('Process called.')
            })
            .catch(error => {
                console.error('Error calling auto-notification: ', error, error.message)
                alert('Error calling auto-notification: ' + error.message)
            })
        }
    }

    getAdvancedOptions() {
        if (process.env.REACT_APP_ENVIRONMENT === 'SBLQA' || !process.env.REACT_APP_ENVIRONMENT) {
            return (
                <div className="advanced-options">
                    <hr/>
                    <div className="advanced-title">
                        Advanced Options (QA only)
                    </div>

                    <div className="recipient">
                        <label>Divert Resulting Email to Alternate Address:&nbsp;&nbsp;</label>
                        <input name='alternate'
                            maxLength="50"
                            value={this.state.alternate}
                            onChange={this.handleInputChanges}
                            size="50"
                            type="email"
                        />
                    </div>

                    <div className="button-section">
                        <AdminButton onClick={this.onReset}>Reset Notification Records</AdminButton>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <AdminButton onClick={(e) => this.onTriggerAuto(e, 'none')}>Trigger Full Notification Process</AdminButton>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <AdminButton onClick={(e) => this.onTriggerAuto(e, 'failure')}>Trigger Induced Failure</AdminButton>
                    </div>
                </div>
            )
        }

        return ''
    }

    render() {
        return (
            <div className="notification-test">
                <div className="notification-content">
                    <div className="notification-title">
                        <h2>Process Book/Review Notifications</h2>
                        <div>
                            This page allows you to trigger the standard book notifications email for the specified member.
                        </div>
                    </div>

                    <div className="recipient">
                        <label>Member Email:&nbsp;&nbsp;</label>
                        <input name='recipient'
                            maxLength="50"
                            value={this.state.recipient}
                            onChange={this.handleInputChanges}
                            size="50"
                            type="email"
                            autoFocus
                        />
                        <Checkbox name="bccAdmin" checked={this.state.bccAdmin} onChange={this.onCheckboxChange}>Send blind courtesy copy of generated email to you?</Checkbox>
                    </div>

                    <div className="button-section">
                        <AdminButton onClick={this.onSubmit}>Run Notification Process Now</AdminButton>
                    </div>

                    {this.getAdvancedOptions()}
                </div>
            </div>
        )
    }
}
